import { Fragment } from "react";
import { useSession } from "next-auth/react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import classNames from "classnames";
import { NAVIGATION as publicNavigation } from "@/components/nav/nav";
import { getPath } from "@/components/nav/navbar";
import Button from "@/components/button";
import { XmasDoubleBell } from "../svg";
import Sidebar from "@/features/dashboard/nav/sidebar";

export default function MobileDrawer({ open, setOpen }) {
  const { data: session } = useSession();
  const closeSidebarWithDelay = () => {
    setTimeout(() => setOpen(false), 200);
  };
  const navItemClasses = classNames(
    "-ml-1 p-1 flex text-dark-purple hover:text-light-purple",
    "hover:opacity-80 font-medium text-sm"
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40 md:hidden" onClose={setOpen}>
        {/* translucent overlay at the back when panel is open */}
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-30" />
        </Transition.Child>

        <div className="fixed inset-0 flex z-40">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
              <div className="flex justify-end z-50">
                <button
                  type="button"
                  className="absolute top-2 right-2 p-2 rounded-full text-gray-3 ring-0 focus:outline-none"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {session ? (
                <div className="font-medium">
                  <Sidebar isDrawer={true} />
                </div>
              ) : (
                <div className="mt-10 py-6 px-7 space-y-6 relative">
                  <div>
                    <Button
                      className="text-sm relative"
                      thin={true}
                      href="/create-shipment"
                      onClick={closeSidebarWithDelay}
                    >
                      {process.env.NEXT_PUBLIC_THEME === "XMAS" && (
                        <div
                          className={classNames(
                            "absolute -top-4 -right-5 pointer-events-none"
                          )}
                        >
                          <XmasDoubleBell />
                        </div>
                      )}
                      Ship Now
                    </Button>
                  </div>
                  <div className="space-y-2">
                    <Link href="/dashboard/get-a-quote">
                      <a className={navItemClasses}>Get A Quote</a>
                    </Link>
                    <Link href="/register">
                      <a className={navItemClasses}>Register</a>
                    </Link>
                    <Link href="/sign-in">
                      <a className={navItemClasses}>Sign In</a>
                    </Link>
                  </div>
                </div>
              )}

              <div className="py-8">
                {publicNavigation.dropdowns.map((dropdown) => (
                  <div key={dropdown.name} className="mt-2 pb-8">
                    <p className="pb-3 px-7 border-b border-gray-2 text-subheading-gray font-medium text-sm">
                      {dropdown.name}
                    </p>
                    {dropdown.sections.map((section) => (
                      <div key={section.name} className="mt-6 px-7 space-y-2">
                        <p className="text-subheading-gray text-vsm">
                          {section.name}
                        </p>
                        <div className="space-y-2">
                          {section.items.map((item) => {
                            let path = getPath(item, dropdown);
                            if (item.name === "FAQ") {
                              return (
                                <a
                                  key={path}
                                  href={item.path}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={navItemClasses}
                                  onClick={closeSidebarWithDelay}
                                >
                                  {item.name}
                                </a>
                              );
                            }
                            return (
                              <Link key={path} href={path}>
                                <a
                                  className={navItemClasses}
                                  onClick={closeSidebarWithDelay}
                                >
                                  {item.name}
                                </a>
                              </Link>
                            );
                          })}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
