import Link from "next/link";

export default function PreviewAlert({ preview }) {
  return (
    preview && (
      <div className="bg-subheading-gray p-2 text-white">
        <div className="text-center text-sm">
          <>
            This page is a preview.{" "}
            <Link href="/api/exit-preview">
              <a className="underline hover:text-cyan duration-200 transition-colors">
                Click here
              </a>
            </Link>{" "}
            to exit preview mode.
          </>
        </div>
      </div>
    )
  );
}
