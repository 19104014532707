import PreviewAlert from "@/components/preview-alert";
import NavPublic from "@/components/nav/nav";
import Footer from "@/components/footer";
import Meta from "@/components/meta";
import { useRouter } from "next/router";

export default function Layout({ preview, children, showFooter = true }) {
  const router = useRouter();

  return (
    <>
      <Meta />
      <div className="">
        {!router.route.includes("/create-shipment") && <NavPublic />}
        <PreviewAlert preview={preview} />
        <main>{children}</main>
      </div>
      {showFooter && <Footer />}
    </>
  );
}
