import { ColoredLogoWithName, ExternalLinkSVG } from "@/components/svg";
import classNames from "classnames";
import { GoogleEmbedMap } from "@/components/google-embed-map";

export default function Footer() {
  const headerClasses =
    "mb-2 text-white text-sm md:text-base font-medium whitespace-nowrap";
  const textClasses = "text-light-purple text-vsm md:text-sm font-semibold";

  return (
    <footer className="bg-footer-black">
      <div className="relative h-full max-w-7xl m-auto px-8 md:px-12 py-12 md:py-24">
        <div className="grid lg:grid-cols-2 gap-x-8">
          <div className="col-span-1 text-left">
            <div className="w-64 mb-12 lg:mb-28">
              <ColoredLogoWithName id="footer-logo" />
            </div>
            <div className="grid grid-rows-2 gap-y-8">
              <div className="flex gap-x-6 sm:gap-x-12">
                <div className="flex-1 sm:flex-none">
                  <p className={headerClasses}>Opening Hours</p>
                  <p className={textClasses}>
                    Mon - Fri
                    <br />
                    9 am - 5:30 pm
                    <br />
                    (excl. public holidays)
                  </p>
                </div>
                <div className="flex-1 sm:flex-none">
                  <p className={headerClasses}>Address</p>
                  <p className={textClasses}>
                    18 Howard Road, Novelty BizCentre, #07-02
                    <br />
                    Singapore 369585
                  </p>
                </div>
              </div>
              <div>
                <p className={headerClasses}>Contact Number</p>
                <a
                  href="https://wa.me/6591187971"
                  className={classNames(textClasses, "hover:opacity-70")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  +65 9118 7971 (WhatsApp Only)
                  <span className="text-light-purple ml-2">
                    <ExternalLinkSVG />
                  </span>
                </a>
              </div>
            </div>
          </div>

          <div className="col-span-1">
            <GoogleEmbedMap />
          </div>
        </div>
      </div>
    </footer>
  );
}
