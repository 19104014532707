import { useState } from "react";
import Navbar from "./navbar";
import MobileDrawer from "./mobile-drawer";

export const NAVIGATION = {
  dropdowns: [
    {
      id: "countries",
      name: "Countries",
      sections: [
        {
          id: "Asia",
          name: "Asia",
          items: [
            { name: "China" },
            { name: "Hong Kong" },
            { name: "India" },
            { name: "Japan" },
            { name: "Malaysia" },
            { name: "South Korea" },
          ],
        },
        {
          id: "Europe",
          name: "Europe",
          items: [
            { name: "Belgium" },
            { name: "Denmark" },
            { name: "France" },
            { name: "Finland" },
            { name: "Germany" },
            { name: "Greece" },
            { name: "Italy" },
            { name: "Netherlands" },
            { name: "Portugal" },
            { name: "Spain" },
            { name: "Sweden" },
            { name: "UK" },
          ],
        },
        {
          id: "N-America",
          name: "N. America",
          items: [{ name: "Canada" }, { name: "USA" }],
        },
        {
          id: "Oceania",
          name: "Oceania",
          items: [{ name: "Australia" }, { name: "New Zealand" }],
        },
        {
          id: "Worldwide",
          name: "Worldwide",
          items: [{ name: "All", path: "/countries" }],
        },
      ],
    },
    {
      id: "use-cases",
      name: "Use Cases",
      sections: [
        {
          id: "use-cases",
          name: "",
          items: [
            { name: "Ecommerce Sellers", path: "/ecommerce-shipping" },
            { name: "Relocation", path: "/relocation-services" },
            { name: "Dangerous Goods", path: "/dangerous-goods" },
            { name: "Pack and Ship", path: "/packing-shipping-services" },
          ],
        },
      ],
    },
    {
      id: "pricing",
      name: "Pricing",
      sections: [
        {
          id: "pricing",
          name: "",
          items: [
            { name: "Rate Calculator", path: "/dashboard/get-a-quote" },
            { name: "Our Prices", path: "/prices" },
          ],
        },
      ],
    },
    {
      id: "support",
      name: "Support",
      sections: [
        {
          id: "support",
          name: "",
          items: [
            { name: "FAQ", path: "https://support.justship.sg" },
            { name: "Blog", path: "/blog" },
          ],
        },
      ],
    },
  ],
  pages: [],
};

export default function NavPublic() {
  const [open, setOpen] = useState(false);

  return (
    <div className={`sticky top-0 z-40`}>
      <MobileDrawer open={open} setOpen={setOpen} />

      <Navbar navigation={NAVIGATION} setOpen={setOpen} />
    </div>
  );
}
