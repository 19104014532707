import React from "react";
export const GoogleEmbedMap = () => {
  return (
    <div className="relative pb-[75%] h-0 truncate">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.748455748965!2d103.8983586!3d1.3268908!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da11e75385a0b1%3A0x983fdaa8ea899853!2sJustShip!5e0!3m2!1sen!2ssg!4v1657537089256!5m2!1sen!2ssg"
        style={{
          border: 0,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        allowFullScreen={false}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="justship-on-google-maps"
      ></iframe>
    </div>
  );
};
